import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import Icon from '@/assets/img/tmp/donors/event_ico@2x.png';
import Obj1 from '@/assets/img/tmp/donors/event_obj1@2x.png';
import Event1 from '@/assets/img/tmp/donors/story_img1@2x.jpg';
import Event2 from '@/assets/img/tmp/donors/story_img2@2x.jpg';
import AdminBanner from '@/components/AdminBanner';
import ButtonMoreView from '@/components/ButtonMoreView';
import { CampaignData } from '@/components/Card/CampaignCard';
import RelativeBoardCard from '@/components/Card/RelativeBoardCard';
import ContactUs from '@/components/ContactUs';
import Container from '@/components/Container';
import Image from '@/components/Image';
import LinkSafe from '@/components/LinkSafe';
import PageComponent from '@/components/PageComponent';
import Section, { SectionNews } from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import SwiperBasic from '@/components/Swiper/SwiperBasic';
import { Tit } from '@/components/Titles';
import YoutubeSingle from '@/components/YoutubeSingle';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';

const contactData = [
  {
    id: 1,
    tit: `문의`,
    costumer: `후원자관계팀`,
    tel: `02-737-1004`,
    email: `donor@unicef.or.kr`,
  },
];

const StoryArticel = styled.div`
  .youtube-container {
    margin-top: 96px;
  }
  .dl-flex {
    display: flex;
    align-items: flex-start;
    margin: 0 -32px;
    margin-top: 32px;

    &.t2 {
      margin-top: 96px;
    }
    dt {
      width: 51.4%;
      padding: 0 32px;

      .img-wrap {
        position: relative;

        &:after {
          content: '';
          display: block;
          position: absolute;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          z-index: -1;
        }
      }
    }

    dd {
      width: 48.6%;
      padding: 0 32px;
      padding-top: 40px;

      .dec {
        line-height: 1.7;
      }
      .blit-list {
        margin-top: 32px;
      }
    }
  }

  &.obj1 {
    .img-wrap {
      &:after {
        width: 584px;
        height: 487px;
        top: -98px;
        left: 265px;
        background-image: url(${Obj1});
      }
    }
  }

  .bn-item {
    display: block;
    margin-top: 32px;
  }

  ${breakpoint(1160)} {
    &.obj1 {
      .img-wrap {
        &:after {
          width: 50.34vw;
          height: 41.98vw;
          top: -8.45vw;
          left: 22.84vw;
        }
      }
    }
  }
  ${breakpoint(`mobile`)} {
    .youtube-container {
      margin: 0 -20px;
      margin-top: 32px;
    }
    .dl-flex {
      margin: 0 -20px;
      margin-top: 24px;
      flex-wrap: wrap;

      &.t2 {
        margin-top: 24px;
      }
      dt,
      dd {
        width: 100%;
        padding: 0;
      }

      dd {
        padding: 0 20px;
        padding-top: 24px;

        .dec {
          line-height: 2;
        }
        .blit-list {
          margin-top: 24px;
        }
      }
    }
    .bn-item {
      margin-top: 48px;
    }

    &.obj1 {
      .img-wrap {
        &:after {
          width: 90.31vw;
          height: 75.31vw;
          top: -18.13vw;
          left: 53.75vw;
        }
      }
    }
  }
`;

const DlContainer = styled.div`
  padding-top: 240px;
  dl {
    display: flex;
    align-items: flex-start;
    padding: 32px 0;
    border-bottom: 1px solid #f8f9fa;

    &:first-child {
      padding-top: 0;
    }

    dt {
      width: 34%;

      ${Tit} {
        position: relative;
        padding-left: 25px;

        &::before {
          content: '';
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 100%;
          background: #1cabe2;
          position: absolute;
          left: 0;
          top: 15px;
        }

        strong {
          color: #1cabe2;
        }
      }
    }
    dd {
      width: 66%;
      line-height: 1.7;

      .dec {
        line-height: 1.7;
      }
      .box {
        margin-top: 18px;
        background: #f8f9fa;
        padding: 32px 40px;
        .tit {
          color: #3162af;
          line-height: 2;
        }
      }

      .blit-list {
        margin-top: 16px;
        li {
          &::before {
            background: #2d2926;
          }
        }
      }
    }
  }

  ${breakpoint(`tablet`)} {
    padding-top: 96px;
    dl {
      dt {
        ${Tit} {
          &::before {
            top: 11px;
          }
        }
      }
      dd {
        padding-left: 12px;
        .blit-list {
          margin-left: -12px;
        }
      }
    }
  }
  ${breakpoint(`mobile`)} {
    dl {
      display: block;

      dt,
      dd {
        width: 100%;
      }
      dd {
        padding-left: 0;
        margin-top: 16px;
        width: calc(100% + 40px);
        margin-left: -20px;
        .dec {
          padding: 0 20px;
        }
      }
    }
  }
`;

const Quote = styled.div`
  width: 100%;
  max-width: 948px;
  margin: 0 auto;

  .box {
    background: #e2f0f6;
    border-radius: 100px 0 100px 0;
    padding: 40px 20px;
    text-align: center;
    position: relative;

    &::before,
    &::after {
      content: '“';
      display: inline-block;
      position: absolute;
      top: 32px;
      left: 112px;
      font-size: 48px;
      color: #b7b8ba;
      font-weight: 700;
    }
    &::after {
      content: '”';
      left: auto;
      right: 112px;
    }

    .dec-wrap {
      width: 100%;
      max-width: 480px;
      margin: 0 auto;
    }
    .from {
      display: block;
      position: relative;
      margin-top: 24px;
      padding-top: 16px;
      &::before {
        content: '';
        display: block;
        width: 40px;
        height: 2px;
        background: #1cabe2;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, 0);
      }
    }

    ${breakpoint(1160)} {
      &::before,
      &::after {
        font-size: 4.14vw;
      }
    }
    ${breakpoint(948)} {
      &::before {
        left: 11.81vw;
      }
      &::after {
        right: 11.81vw;
      }
    }
    ${breakpoint(`tablet`)} {
      border-radius: 0;
      padding: 52px 20px;
      padding-bottom: 46px;

      &::before,
      &::after {
        font-size: 26px;
        top: 14px;
        left: 38px;
      }
      &::after {
        left: auto;
        right: 38px;
      }
    }
  }
`;
const SwiperCustom = styled(SwiperBasic)`
  margin-top: 275px;
  margin-bottom: 120px;
  padding: 0;
  .swiper-container {
    overflow: hidden;

    .swiper-slide {
      overflow: hidden;
      width: 100%;
    }
  }

  ${breakpoint(`tablet`)} {
    margin-top: 97px;
    margin-bottom: 62px;

    .swiper-container {
      margin-left: -20px;
      margin-right: -20px;
      padding-bottom: 98px !important;
    }
    .swiper-scrollbar {
      display: none;
    }

    .swiper-button-prev,
    .swiper-button-next {
      display: block;
    }
  }

  ${breakpoint(`mobile`)} {
    .swiper-container {
      .swiper-slide {
        width: 100%;
        margin-right: 0 !important;
      }
    }
  }
`;

const Request = styled.dl`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 100px 0;

  dd {
    width: 100%;
    max-width: 256px;
  }
  ${Tit} {
    position: relative;
    padding-left: 100px;

    &::before {
      content: '';
      display: block;
      width: 80px;
      height: 80px;
      position: absolute;
      background: url(${Icon}) center no-repeat;
      background-size: cover;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  ${ButtonMoreView} {
    width: 100%;
  }

  ${breakpoint(`mobile`)} {
    margin-bottom: 24px;
    ${Tit} {
      padding: 16px 0;
      padding-left: 100px;
    }
    dt,
    dd {
      width: 100%;
      max-width: 100%;
    }
    dd {
      margin-top: 24px;
    }
  }
`;
const ContactusContainer = styled.div`
  margin-top: 96px;
`;

const Story: React.FC<PageProps> = ({ location }) => {
  const [thumbs, setThumbs] = useState<CampaignData[]>([]);

  const loadThumbs = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['280', '290', '300'],
        pagePerCount: 8,
        category: ['A032'],
      });
      const articles = data as any;
      setThumbs(
        articles
          .map((article: BoardVo) => ({
            id: article.boardIndexNumber,
            title: article.subject,
            pcImage: article.imgAttGrpNoTn,
            mobileImage: article.imgAttGrpNoTn,
            link: `/what-we-do/news/${article.boardIndexNumber}`,
            date: article.firstRegisterDate,
            boardCategoryCode: article.boardCategoryCode,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadThumbs();
  }, [loadThumbs]);

  return (
    <LayoutWithTitle
      location={location}
      title="참여 활동"
      description="for every child, activities"
    >
      <Section className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  후원자님의 소중한 의견을 <br />
                  듣고 함께합니다
                </PageComponent>
              </Tit>
            </h2>
            <p className="header-dec">
              <PageComponent id="content1">
                더 나은 유니세프한국위원회를 위한 소중한 후원자님의 의견을 듣고,
                모든 어린이가 행복한 세상을 위해 다양한 참여 활동을 진행하고
                있습니다.
              </PageComponent>
            </p>
          </SectionHeader>

          <StoryArticel className="obj1">
            <Tit size="s2">유니프렌즈</Tit>
            <dl className="dl-flex">
              <dt>
                <div className="img-wrap">
                  <Image pcSrc={Event1} mobileSrc={Event1} />
                </div>
              </dt>
              <dd>
                <p className="dec">
                  유니프렌즈는 유니세프의 정기 후원자로서 유니세프의 활동 및
                  아동 권리에 대해 배우고 온라인 상에서 홍보하며, 유니세프의
                  활동에 참여하는 목적으로 창단되었습니다.
                </p>
              </dd>
            </dl>
          </StoryArticel>

          <DlContainer>
            <dl>
              <dt>
                <Tit size="s4-1">
                  <strong>누가</strong> 할 수 있나요?
                </Tit>
              </dt>
              <dd>
                <p className="dec">
                  유니세프 <strong>정기후원자</strong>
                </p>
                <div className="box">
                  <strong className="tit">이런 분들을 기다리고 있어요</strong>
                  <ul className="blit-list">
                    <li>
                      유니세프 한국위원회에 대해 더 잘 알고 싶으신 후원자님
                    </li>
                    <li>
                      다른 후원자님들과 소통하며 후원의 의미에 대해 한 번 더
                      생각해보고 싶으신 후원자님
                    </li>
                    <li>
                      유니세프 후원자로서 더 빠르게 소식을 받아보고, 관련 활동에
                      참여하고 싶으신 후원자님
                    </li>
                    <li>
                      유니세프 한국위원회와 함께 색다르고 재미있는 경험을 해보고
                      싶으신 후원자님
                    </li>
                  </ul>
                </div>
              </dd>
            </dl>
            <dl>
              <dt>
                <Tit size="s4-1">
                  <strong>어떤 활동</strong>을 하나요?
                </Tit>
              </dt>
              <dd>
                <p className="dec">
                  <ul className="blit-list">
                    <li>유니프렌즈 미션 활동 및 온라인 홍보</li>
                    <li>온오프라인 행사 및 교육 참여</li>
                    <li>설문조사 참여</li>
                  </ul>
                </p>
              </dd>
            </dl>
            <dl>
              <dt>
                <Tit size="s4-1">
                  <strong>언제, 어떻게</strong> 지원할 수 있나요?
                </Tit>
              </dt>
              <dd>
                <p className="dec">
                  매년 상하반기에 진행되며, 상하반기 각 1회씩 홈페이지를 통해
                  모집 (활동에 따라 모집 주기 및 기간은 변경될 수 있습니다)
                </p>
              </dd>
            </dl>
          </DlContainer>

          <SwiperCustom>
            <Swiper
              slidesPerView="auto"
              navigation
              scrollbar={{ draggable: true }}
            >
              <SwiperSlide>
                <Quote>
                  <div className="box">
                    <div className="dec-wrap">
                      <Tit size="s4">
                        ‘그저 납부만 잘 하면 되겠지’ 하던 생각을 바꿀 수 있게
                        해주셔서 감사합니다. ‘후원금 납부는 나눔의 일부일 뿐
                        진실된 마음과 사랑, 자부심으로 후원했을 때 진정한 그
                        의미가 생기는구나’를 느끼고 경험했습니다. 유니프렌즈
                        활동을 통해 위와 같은 체험을 할 수 있게 해주시고, 또
                        후원자로서 스스로에 대한 만족감을 얻을 수 있어서 참
                        다행이고 감사합니다! 앞으로 오래오래 함께 해요!
                      </Tit>
                      <p className="from">안O혜 후원자님</p>
                    </div>
                  </div>
                </Quote>
              </SwiperSlide>
              <SwiperSlide>
                <Quote>
                  <div className="box">
                    <div className="dec-wrap">
                      <Tit size="s4">
                        매주 주어지는 미션마다 아동의 권리와 후원이 가지는
                        마음을 생각할 수 있었습니다. 언제나 세상을 변화시키기
                        위한 걸음에 앞장서서 힘써 주심에 감사드립니다.
                      </Tit>
                      <p className="from">이O진 후원자님</p>
                    </div>
                  </div>
                </Quote>
              </SwiperSlide>
              <SwiperSlide>
                <Quote>
                  <div className="box">
                    <div className="dec-wrap">
                      <Tit size="s4">
                        유니프렌즈 활동이 더 많이 홍보되어 참여자가 많아지고, 더
                        많은 사람들이 유니세프 활동과 그 다양성을 충분히
                        이해하는 계기가 되면 좋겠습니다.
                      </Tit>
                      <p className="from">이O혜 후원자님</p>
                    </div>
                  </div>
                </Quote>
              </SwiperSlide>
              <SwiperSlide>
                <Quote>
                  <div className="box">
                    <div className="dec-wrap">
                      <Tit size="s4">
                        유니프렌즈 활동을 통해 아동의 권익에 대해 관심을 가지게
                        됐습니다. 활동하는 동안 유니세프의 일원이라는 자부심도
                        가지게 된 뜻 깊은 시간이었습니다. 앞으로도 이런 기회가
                        생긴다면 또 지원할 것이고, 유니프렌즈를 통해 오랜만에
                        일상에서 생동감을 느껴서 행복했습니다
                      </Tit>
                      <p className="from">최O미 후원자님</p>
                    </div>
                  </div>
                </Quote>
              </SwiperSlide>
            </Swiper>
          </SwiperCustom>
          <div className="bn-item">
            <AdminBanner idx="1" kind="0003" />
          </div>
        </Container>
      </Section>

      <Section className="by-sub-main-layout">
        <Container>
          <StoryArticel className="obj1">
            <Tit size="s2">후원자 합창단</Tit>
            <PageComponent id="youtube1">
              <YoutubeSingle
                className="youtube-container"
                videoId="s6XRXpU7lVg"
              />
            </PageComponent>
            <dl className="dl-flex t2">
              <dt>
                <div className="img-wrap">
                  <Image pcSrc={Event2} mobileSrc={Event2} />
                </div>
              </dt>
              <dd>
                <p className="dec">
                  유니세프한국위원회는 2016년 유니세프 창립 70주년을 맞아 후원자
                  합창단을 창단했습니다. 모든 어린이가 행복한 세상을 만들기 위해
                  후원자 합창단은 아름다운 하모니로 노래합니다.
                </p>
              </dd>
            </dl>
          </StoryArticel>
          <DlContainer>
            <dl>
              <dt>
                <Tit size="s4-1">
                  <strong>누가</strong> 할 수 있나요?
                </Tit>
              </dt>
              <dd>
                <p className="dec">
                  유니세프 <strong>정기후원자</strong>
                </p>
              </dd>
            </dl>
            <dl>
              <dt>
                <Tit size="s4-1">
                  <strong>어떤 활동</strong>을 하나요?
                </Tit>
              </dt>
              <dd>
                <p className="dec">유니세프 행사 공연, 외부 초청 공연</p>
              </dd>
            </dl>
            <dl>
              <dt>
                <Tit size="s4-1">
                  <strong>언제, 어떻게</strong> 지원할 수 있나요?
                </Tit>
              </dt>
              <dd>
                <p className="dec">웹사이트를 통해 수시모집</p>
              </dd>
            </dl>
          </DlContainer>

          <Request>
            <dt>
              <Tit size="s3-1">
                유니세프 후원자 합창단 <br />
                공연이 필요하신가요?
              </Tit>
            </dt>
            <dd>
              <ButtonMoreView mode="sky">
                <LinkSafe
                  to="https://docs.google.com/forms/d/e/1FAIpQLScnE6Ejza911eAEUUFuIzzk-ALi_ld0by3yhRaLqpzLwNlODw/viewform?c=0&w=1"
                  target="_blank"
                >
                  공연 요청하기
                </LinkSafe>
              </ButtonMoreView>
            </dd>
          </Request>
          <div className="bn-item">
            <AdminBanner idx="2" kind="0003" />
          </div>

          <ContactusContainer>
            <ContactUs itemData={contactData} />
          </ContactusContainer>
        </Container>
      </Section>

      <SectionNews className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">최근 소식</Tit>
              </h2>
              <LinkSafe to="/what-we-do/news?category=A032" className="more">
                <span className="txt">더 보기</span>
              </LinkSafe>
            </div>
          </SectionHeader>
          {thumbs.length > 0 && (
            <RelativeBoardCard
              newslist={thumbs}
              isDate={false}
              isSwiper
              overflowHidden
            />
          )}
        </Container>
      </SectionNews>
    </LayoutWithTitle>
  );
};

export default Story;
