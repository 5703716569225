/* eslint-disable no-nested-ternary */
import LinkSafe from './LinkSafe';
import { FoBannerControllerService } from '@/__generated__/FrontApi';
import Image from '@/components/Image';
import React, { FC, useCallback, useEffect, useState } from 'react';

interface BannerPropsData {
  banners?: BannerTypeData[];
  kind: string;
  idx: any;
}

export interface BannerTypeData {
  targetUrl?: string;
  targetBlankYn?: string;
  imgAttGrpNoPc: string;
  imgAttGrpNoMobile: string;
  title: string;
}

const AdminBanner: FC<BannerPropsData> = ({ ...props }) => {
  const { kind } = props;
  const { idx } = props;
  const [banner, setBanner] = useState([]);
  const loadBanner = useCallback(() => {
    FoBannerControllerService.selectBannerListUsingGet({
      kind,
    })
      .then(({ data }) => {
        setBanner({ ...data });
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  useEffect(() => {
    loadBanner();
  }, []);

  return (
    banner.length > 0 &&
    (banner[idx]?.targetUrl !== `` ? (
      banner[idx]?.targetBlankYn !== `Y` ? (
        <LinkSafe to={banner[idx]?.targetUrl} className="bn-item">
          <Image
            pcSrc={banner[idx]?.imgAttGrpNoPc}
            mobileSrc={banner[idx]?.imgAttGrpNoMobile}
            alt={banner[idx]?.title}
          />
        </LinkSafe>
      ) : (
        <LinkSafe
          to={banner[idx]?.targetUrl}
          className="bn-item"
          target="_blank"
        >
          <Image
            pcSrc={banner[idx]?.imgAttGrpNoPc}
            mobileSrc={banner[idx]?.imgAttGrpNoMobile}
            alt={banner[idx]?.title}
          />
        </LinkSafe>
      )
    ) : (
      <Image
        pcSrc={banner[idx]?.imgAttGrpNoPc}
        mobileSrc={banner[idx]?.imgAttGrpNoMobile}
        alt={banner[idx]?.title}
      />
    ))
  );
};
export default AdminBanner;
